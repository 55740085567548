import './App.css';
import { Routes,  Route } from 'react-router-dom'
import PrivateRoute from './utils/PrivateRoute'
import HomePage from './pages/HomePage'
import LoginPage from './pages/LoginPage'
import PassForm from './pages/PassForm'
import { useContext} from 'react';
import AuthContext from './context/AuthContext'
import CreatePassword from './pages/CreatePassword';
import ForgetPassword from './pages/ForgetPassword';
import NewPassword from './pages/NewPassword';

function App() {
  const {islogged} = useContext(AuthContext)
  return (
    <>
          <Routes>
          <Route path="/*" element={!islogged ? <LoginPage /> : <HomePage /> }  />
          <Route element={<PrivateRoute />}>
              <Route path='/passform' element={<PassForm />} />
              <Route path='/createpassword/:id' element={<CreatePassword />} />
          </Route>
          <Route path='/forgetpassword' element={<ForgetPassword />} />
          <Route path='/password/reset/confirm/:uid/:token' element={<NewPassword />} />
          <Route path='/logout' element={<LoginPage />} />
          </Routes>
    </>
  );
}

export default App;
