import React, { useState, useEffect } from 'react'
import Loading from '../components/Loading'
import useAxios from '../utils/useAxios'
import './home.css'

function PrivilegeCustomer() {


  const [search, setSearch] = useState('')
  const [loading,setLoading] = useState(false)
  const find = (data) => {
    return data.filter((item) => item.name.toUpperCase().includes(search) ||
      item.email.toUpperCase().includes(search) ||
      item.id == search ||
      item.phone_number.includes(search))
  }


  let api = useAxios()
  useEffect(() => {
    getNotes()
    privi()
  }, [search])


  let [notes, setNotes] = useState([])
  let getNotes = async () => {
    let response = await api.get('/api/hotelhome/')
    if (response.status === 200) {
      setNotes(response.data)
      setLoading(true)
    }
  }


  let [privilege, setPrivilege] = useState([])
  let privi = async () => {
    let respo = await api.get('/api/set_privilage/')
    if (respo.status === 200) {
      setPrivilege(respo.data)
    }
  }

  return (
    <div className='first'>
      {loading ? <>
      <div className='allcustomerheader'>
        <span>List Of The All VIPs</span>
        <input type="search" className='allcustomerinput' placeholder='Search...' onChange={(e) => setSearch(e.target.value.toUpperCase())} />
      </div>
      <div className='outertable'>
      <table className='table'>
        <thead>
          <tr style={{ 'fontSize': '17px' }}>
            <th style={{'borderRadius':'20px 0 0 0 ','maxWidth':'60px'}}>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Contact</th>
            <th>Visit</th>
            <th>Purchase</th>
            <th>Discount</th>
            <th style={{'borderRadius':'0 20px 0 0 '}}>Category</th>
          </tr>
        </thead>
        <tbody>
          {find(notes).map(note => (
            (note.visit >= privilege.silver_target_visit && note.purchase >= privilege.silver_target_purchase && (privilege.silver_target_visit != 0 || privilege.gold_target_visit != 0 || privilege.diamond_target_visit != 0 || privilege.silver_target_purchase != 0 || privilege.gold_target_purchase != 0 || privilege.diamond_target_purchase != 0)) &&
            <tr className={`${(note.visit >= privilege.diamond_target_visit && note.purchase >= privilege.diamond_target_purchase) ? 'diamond' : (note.visit >= privilege.gold_target_visit && note.purchase >= privilege.gold_target_purchase) ? 'gold' : 'silver' }`} style={{ 'fontSize': '17px' }} key={note.id}>
              <td>{note.id}</td>
              <td >{note.name}</td>
              <td>{note.email}</td>
              <td>{note.phone_number}</td>
              <td>{note.visit}</td>
              <td>{note.purchase}</td>
              <td>
                {(() => {
                  if (privilege.diamond_target_visit == 0 && privilege.diamond_target_purchase == 0) {

                    if (privilege.gold_target_visit == 0 && privilege.gold_target_purchase == 0) {
                      if (privilege.silver_target_visit == 0 && privilege.silver_target_purchase == 0) {
                        return <h3>Fill Privilege Form</h3>
                      }
                      else {
                        return <h2>{privilege.silver_offer}%</h2>
                      }
                    }

                    else {
                      if (note.visit >= privilege.gold_target_visit && note.purchase >= privilege.gold_target_purchase) {
                        return <h2>{privilege.gold_offer}</h2>
                      }
                      else {
                        return <h2>{privilege.silver_offer}%</h2>
                      }
                    }

                  }


                  else {
                    if (note.visit >= privilege.diamond_target_visit && note.purchase >= privilege.diamond_target_purchase) {
                      return <h2>{privilege.diamond_offer}%</h2>
                    }
                    else if (note.visit >= privilege.gold_target_visit && note.purchase >= privilege.gold_target_purchase) {
                      return <h2>{privilege.gold_offer}%</h2>
                    }
                    else {
                      return <h2>{privilege.silver_offer}%</h2>
                    }
                  }
                })()}
              </td>
              <td>
                {(() => {
                  if (privilege.diamond_target_visit == 0 && privilege.diamond_target_purchase == 0) {

                    if (privilege.gold_target_visit == 0 && privilege.gold_target_purchase == 0) {
                      if (privilege.silver_target_visit == 0 && privilege.silver_target_purchase == 0) {
                        return <h3>Fill Privilege Form</h3>
                      }
                      else {
                        return <h2>Silver</h2>
                      }
                    }

                    else {
                      if (note.visit >= privilege.gold_target_visit && note.purchase >= privilege.gold_target_purchase) {
                        return <h2>Gold</h2>
                      }
                      else {
                        return <h2>Silver</h2>
                      }
                    }

                  }


                  else {
                    if (note.visit >= privilege.diamond_target_visit && note.purchase >= privilege.diamond_target_purchase) {
                      return <h2>Diamond</h2>
                    }
                    else if (note.visit >= privilege.gold_target_visit && note.purchase >= privilege.gold_target_purchase) {
                      return <h2>Gold</h2>
                    }
                    else {
                      return <h2>Silver</h2>
                    }
                  }
                })()}
              </td>
            </tr>


          ))}

        </tbody>
      </table>
      </div>
      </> : <Loading />}
    </div>

  )
}

export default PrivilegeCustomer