import React,{useState,useEffect} from 'react'
import image from '../Assets/img.png'
import useAxios from '../utils/useAxios'
import { UilTrash,UilMinusCircle,UilPlusCircle } from '@iconscout/react-unicons'

function Pos(props) {
  const [cart, setCart] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
 

  const  toggle = async(index) => {
    setIsActive(index)
  }





  const addCart = async(product) =>{
    
    let findProductInCart = await cart.find(i=>{
      return i.id === product.id
    });

    if(findProductInCart){
      let newCart = [];
      let newItem;

      cart.forEach(cartItem => {
        if(cartItem.id === product.id){
          newItem = {
            ...cartItem,
            // quantity: cartItem.quantity + 1,
            // totalAmount: cartItem.price * (cartItem.quantity + 1)
          }
          newCart.push(newItem);
        }else{
          newCart.push(cartItem);
        }
      });

      setCart(newCart);

    }else{
      let addingProduct = {
        ...product,
        'quantity': 1,
        'totalAmount': product.price,
      }
      setCart([...cart, addingProduct]);
    }
  }
  const removeProduct = async(product) =>{
    const newCart =cart.filter(cartItem => cartItem.id !== product.id);
    setCart(newCart);
  }

  const decreasequantity = (product)=>{
    if(product.quantity >= 2){
      product.quantity = product.quantity - 1
    }
  }
  const increasequantity = (product)=>{
    product.quantity = product.quantity + 1
  }
  useEffect(() => {
    let newTotalAmount = 0;
    cart.forEach(icart => {
      newTotalAmount = newTotalAmount + parseInt(icart.totalAmount * icart.quantity);
    })
    setTotalAmount(newTotalAmount);
  },[cart,decreasequantity,increasequantity])

  const [added, setAdded] = useState(false)
  const [newvalue, setNewvalue] = useState('')
  const [values, setValues] = useState(totalAmount)
  const submitForm = (id) => { 
    setAdded(true)
    const formdata = new FormData()
    formdata.append('amount', values)
    try {
      api.put('/api/enteramount/' + id + '/', formdata).then((response) => {
        setNewvalue(values)
        setValues('')
      })
      alert('data is added')
    } catch (error) {
      setValues(error)
    }
  }
  







  let [notes, setNotes] = useState([])
  const [isActive, setIsActive] = useState(notes[0]?.id)

    let api = useAxios()
    useEffect(()=> {
        getNotes()
        getNote()
    }, [isActive])
    useEffect(()=>{
        setValues(totalAmount)
    },[totalAmount])


    let getNotes = async() =>{
        let response = await api.get('/api/category/')

        if(response.status === 200){
           setNotes(response.data)
           console.log(response.data)
        }
    }
    const [product,setProduct] = useState([])
    let getNote = async() =>{
      let response = await api.get('/api/product/'+isActive)
      if(response.status === 200){
        setProduct(response.data)
      }
    }
  return (
    <div className='searchbox3'>
      { (notes.length !== 0) &&
            <div className='formbox10'>
              <div className='sliderheader'>
              {notes.map(note =>(
                <div className={isActive===note.id ? 'sliderheaderitem activess' : 'sliderheaderitem'} key={note.id} onClick={()=>toggle(note.id)}>
                  <div className='sliderheaderinneritem'>
                      <img src={`https://vips.vipsnepal.com${note.image}`} alt="" style={{'width':'60px','height':'50px'}} />
                      <h6 style={{'fontSize':'12px'}}>{note.name}</h6>
                  </div>
                </div>
              ))}
              </div>
              <div className='productlistbody'>
                {product.map(pro =>(
                  <div className='productlistitem' key={pro.id} onClick={()=>addCart(pro)}>
                    <div className='productlistinneritem'>
                      <img src={`https://vips.vipsnepal.com${pro.image}`} alt="" style={{'width':'60px','height':'50px'}} />
                      <h6 style={{'fontSize':'12px'}}>{pro.name}</h6>
                      <h6>{pro.price}</h6>
                      </div>
                  </div>
                  ))}
              </div>
            </div>
      }
            <div className='formbox9'>
              <h3>CheckOut</h3>
              {cart ? cart.map((cartProduct,key)=>
                <div className='craftitem' key={key}>

                      <img  src={`https://vips.vipsnepal.com${cartProduct.image}`} alt="" style={{'width':'60px','height':'50px'}} />

                      <div>
                      <h6 style={{'fontSize':'12px'}}>{cartProduct.name}</h6>
                      <div className='displayflex'>
                      <UilMinusCircle onClick={()=>decreasequantity(cartProduct)} />
                      {/* <input type="number" className='checkInSearch5' name='quantity' /> */}
                      <div className='checkInSearch5'>{cartProduct.quantity}</div>
                      <UilPlusCircle onClick={()=>increasequantity(cartProduct)} />
                      </div>
                      </div>

                      <h6>{cartProduct.price}</h6>

                     <UilTrash onClick={() => removeProduct(cartProduct)} />
                </div>
                ) : 'No item in cart'}
                <h5 className=''>Total Amount: Rs.{totalAmount}</h5>

              <div className='combine2'>
                   <div className='singlecardhead4'>
                     <input type="number" value={values} placeholder='Enter Amount' className='checkInSearch6' onChange={(e) => setValues(e.target.value)} required />
                     <button className='checkInSubmit6' onClick={()=>submitForm(props.not)}><UilPlusCircle /> <span>Add</span></button>
                   </div>
                  {!added ? '' :
                  <div className='singlecardhead5'>
                  <div>
                    <p>Total : {newvalue}</p>
                    <p>Discount : {props.offerForm}%</p>
                    <p>Final Amount :{newvalue - props.offerForm * newvalue / 100} </p>
                  </div>
                  </div>
              }
             </div>
          </div>

         </div>
  )
}

export default Pos