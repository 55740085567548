import React, { useState, useEffect } from 'react'
import useAxios from '../utils/useAxios'
import { UilPlusCircle, UilTagAlt, UilApps } from '@iconscout/react-unicons'
import ID from '../Assets/id.png'
import name from '../Assets/name.png'
import visit from '../Assets/visit.png'
import purchase from '../Assets/purchase.png'
import { useParams } from 'react-router'
import Pos from './Pos'


function EachCheckIn() {
  const params = useParams()
  const [added, setAdded] = useState(false)
  const [newvalue, setNewvalue] = useState('')
  const [values, setValues] = useState('')
  const [submit, setSubmit] = useState(true)
  const [search, setSearch] = useState((params.id === 0) ? '' : params.id)
  // console.log(search);
  const handleChange = (event) => {
    setSearch({
      ...search,
      [event.target.name]: event.target.value
    })
  }
  // console.log(search);
  const find = (data) => {
    return data.filter((item) => item.id == search)
  }

  let [note, setNote] = useState([])
  let getNote = async () => {
    let response = await api.get('/api/hotelhome/')
    if (response.status === 200) {
      setNote(response.data)
    }
  }

  let [privilege, setPrivilege] = useState([])
  let privi = async () => {
    let respo = await api.get('/api/set_privilage/')
    if (respo.status === 200) {
      setPrivilege(respo.data)
    }
  }

  let api = useAxios()
  useEffect(() => {
    getNote()
    privi()
   
  }, [search, note])
  
  useEffect(()=>{
    getPoints()
  },[])

  const submitForm = (id) => {
    setAdded(true)
    const formdata = new FormData()
    formdata.append('amount', values)
    try {
      api.put('/api/enteramount/' + id + '/', formdata).then((response) => {
        setNewvalue(values)
        setValues('')
      })
      alert('data is added')
    } catch (error) {
      setValues(error)
    }
  }
  let [calc, setCalc] = useState()
  let getPoints = async () => {
    let response = await api.get('/api/offer/')

    if (response.status === 200) {
      setCalc(response.data)
    }
  }

  const offerForm = (not) => {

    if (privilege.diamond_target_visit == 0 && privilege.diamond_target_purchase == 0) {

      if (privilege.gold_target_visit == 0 && privilege.gold_target_purchase == 0) {
        if (privilege.silver_target_visit == 0 && privilege.silver_target_purchase == 0) {
          return 'Fill Privilege Form'
        }
        else {
          return 'Silver'
        }
      }

      else {
        if (not.visit >= privilege.gold_target_visit && not.purchase >= privilege.gold_target_purchase) {
          return 'Gold'
        }
        else {
          return 'Silver'
        }
      }

    }


    else {
      if (not.visit >= privilege.diamond_target_visit && not.purchase >= privilege.diamond_target_purchase) {
        return (
          privilege.diamond_offer
        )
      }
      else if (not.visit >= privilege.gold_target_visit && not.purchase >= privilege.gold_target_purchase) {
        return (privilege.gold_offer)
      }
      else if (not.visit >= privilege.silver_target_visit && not.purchase >= privilege.silver_target_purchase) {
        return (privilege.silver_offer)
      }
      else {
        return '0'
      }
    }

  }
  return (
    <>

      <div className='checkinwidth'>

      <div className='searchbox2'>
        {find(note).map(not => (
            <div className='formbox3'>
              <div key={not.id} className='singlecard'>
                <div className='innersinglecard'>
                  <div className='forgap'>
                  <h4 style={{ 'display': 'flex', 'alignItems': 'center' }}><div className='iconback'><img src={ID} alt="" /></div> Customer ID : {not.id}</h4>
                  <h4 style={{ 'display': 'flex', 'alignItems': 'center' }}><div className='iconback2'><img src={name} alt="" /></div> Customer Name : {not.name}</h4>
                  {(not.visit >= privilege.silver_target_visit && not.purchase >= privilege.silver_target_purchase) &&
                    <h4 style={{ 'display': 'flex', 'alignItems': 'center' }}><div className='iconback2'><UilApps /></div> Category: {(() => {
                      if (privilege.diamond_target_visit == 0 && privilege.diamond_target_purchase == 0) {

                        if (privilege.gold_target_visit == 0 && privilege.gold_target_purchase == 0) {
                          if (privilege.silver_target_visit == 0 && privilege.silver_target_purchase == 0) {
                            return 'Fill Privilege Form'
                          }
                          else {
                            return 'Silver'
                          }
                        }

                        else {
                          if (not.visit >= privilege.gold_target_visit && not.purchase >= privilege.gold_target_purchase) {
                            return 'Gold'
                          }
                          else {
                            return 'Silver'
                          }
                        }

                      }


                      else {
                        if (not.visit >= privilege.diamond_target_visit && not.purchase >= privilege.diamond_target_purchase) {
                          return 'Diamond'
                        }
                        else if (not.visit >= privilege.gold_target_visit && not.purchase >= privilege.gold_target_purchase) {
                          return 'Gold'
                        }
                        else {
                          return 'Silver'
                        }
                      }
                    })()}</h4>}
                  {(not.visit >= privilege.silver_target_visit && not.purchase >= privilege.silver_target_purchase) &&
                    <h4 style={{ 'display': 'flex', 'alignItems': 'center' }}><div className='iconback2'><UilTagAlt /></div> Discount: {(() => {
                      if (privilege.diamond_target_visit == 0 && privilege.diamond_target_purchase == 0) {

                        if (privilege.gold_target_visit == 0 && privilege.gold_target_purchase == 0) {
                          if (privilege.silver_target_visit == 0 && privilege.silver_target_purchase == 0) {
                            return 'Fill Privilege Form'
                          }
                          else {
                            return 'Silver'
                          }
                        }

                        else {
                          if (not.visit >= privilege.gold_target_visit && not.purchase >= privilege.gold_target_purchase) {
                            return 'Gold'
                          }
                          else {
                            return 'Silver'
                          }
                        }

                      }


                      else {
                        if (not.visit >= privilege.diamond_target_visit && not.purchase >= privilege.diamond_target_purchase) {
                          return privilege.diamond_offer
                        }
                        else if (not.visit >= privilege.gold_target_visit && not.purchase >= privilege.gold_target_purchase) {
                          return privilege.gold_offer
                        }
                        else {
                          return privilege.silver_offer
                        }
                      }
                    })()}%</h4>}
                    </div>

                    <div className='forgap'>
                  <h4 style={{ 'display': 'flex', 'alignItems': 'center' }}> <div className='iconback3'><img src={visit} alt="" /></div> Total Visit: {not.visit}</h4>
                  <h4 style={{ 'display': 'flex', 'alignItems': 'center' }}> <div className='iconback4'><img src={purchase} alt="" /></div> Total Purchase: {not.purchase}</h4>
                  <h4 style={{ 'display': 'flex', 'alignItems': 'center' }}><div className='iconback2'><UilTagAlt /></div>Offer: {(()=>{
                        for(let i=0;i<(calc?.length-1);i++){
                          if((not.purchase/100) >= calc[i].points && (not.purchase/100) <=calc[i+1].points){
                            return calc[i].offer
                          }
                          else if((not.purchase/100) >= calc[(calc?.length-1)].points){
                            return calc[(calc?.length-1)].offer
                          }
                        }
                      
                      })()}</h4>
                      </div>
                </div>
              </div>
            </div>
        ))}
      </div>
      {find(note).map(not=>
          <Pos not={not.id} offerForm={offerForm(not)} />
          )}

      </div>




    </>
  )
}

export default EachCheckIn