import React,{useContext, useState} from 'react'
import axios from 'axios';
import AuthContext from '../context/AuthContext';

function NewPassword({match}) {
    const {baseURL} = useContext(AuthContext)
    const reset_password_confirm = (uid, token, new_password, re_new_password) => async dispatch => {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
    
        const body = JSON.stringify({ uid, token, new_password, re_new_password });
    
        try {
            await axios.post(`${baseURL}/users/reset_password_confirm/`, body, config);
    
            // dispatch({
            //     type: PASSWORD_RESET_CONFIRM_SUCCESS
            // });
        } catch (err) {
            // dispatch({
            //     type: PASSWORD_RESET_CONFIRM_FAIL
            // });
        }
    }
    const [requestSent, setRequestSent] = useState(false);
    const [formData, setFormData] = useState({
        new_password: '',
        re_new_password: ''
    });

    const { new_password, re_new_password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();

        const uid = match.params.uid;
        const token = match.params.token;

        reset_password_confirm(uid, token, new_password, re_new_password);
        setRequestSent(true);
    };

  return (
    <div className='container mt-5'>
            <form onSubmit={e => onSubmit(e)}>
            <div className='form-group'>
                    <input
                        className='form-control'
                        type='password'
                        placeholder='New Password'
                        name='new_password'
                        // value={new_password}
                        // onChange={e => onChange(e)}
                        minLength='6'
                        required
                    />
                </div>
                <div className='form-group'>
                    <input
                        className='form-control'
                        type='password'
                        placeholder='Confirm New Password'
                        name='re_new_password'
                        // value={re_new_password}
                        // onChange={e => onChange(e)}
                        minLength='6'
                        required
                    />
                </div>
                <button className='btn btn-primary' type='submit'>Reset Password</button>
            </form>
        </div>
  )
}

export default NewPassword