import React, { useEffect,useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import useAxios from '../utils/useAxios'
import AuthContext from '../context/AuthContext'
import { UilInfoCircle } from '@iconscout/react-unicons'



function AddOffer() {
    const {baseURL} = useContext(AuthContext)
    const [forms, setForms] = useState({
        'points': '',
        'offer': ''
    })
    const [special,setSpecial] = useState({
        'title':'',
        'detail':''
    })
    let [note, setNote] = useState()


    const handleChange = (event) => {
        setForms({
            ...forms,
            [event.target.name]: event.target.value
        })
    }
    const offerChange = (event) => {
        setSpecial({
            ...special,
            [event.target.name]: event.target.value
        })
    }
    const api = useAxios()
    const submitForm = () => {
        const formdata = new FormData()
        formdata.append('points', forms.points)
        formdata.append('offer', forms.offer)
        try {
            api.post(`${baseURL}/api/offer/`, formdata).then((response) => {
                setForms({
                    'points': '',
                    'offer': '',
                    'statuss': 'success'
                })
            }).catch(()=>{
                setForms({
                    'points': '',
                    'offer': '',
                    'statuss':'error'
                })
            })
        } catch {
        }
    }
    const specialOffer = () => {
        const formdata = new FormData()
        formdata.append('title',special.title)
        formdata.append('detail',special.detail)
            api.post(`${baseURL}/api/specialoffer/`,formdata).then(response => {
                setSpecial({
                    'title':'',
                    'detail':''
                })
            }).catch(()=>{
                setSpecial({
                    'title':'',
                    'detail':''
                })
            })
    }
    useEffect(() => {
        getNotes()
    }, [submitForm])
    let getNotes = async () => {
        let response = await api.get('/api/offer/' )

        if (response.status === 200) {
         setNote(response.data)
        }
    }

    
  return (
    <div className='editcustomer' >

      <div className='formboxcontainer'>
         <div>
            <h3 className='headingalign'>Offer <UilInfoCircle style={{"marginLeft":'10px'}} /> <span style={{'fontSize':'10px'}}>Add offer to display list of points and offer as like in right side.</span> </h3>
         </div>
        <div className='formbox8'>
        <form action="" className='formcontainer2'>
            <label htmlFor="" className='addcustomerheader2'>Points</label>
            <input value={forms.points} className='checkInSearch3' name='points' onChange={handleChange} type="number" />
            <label htmlFor="" className='addcustomerheader2'>Offer</label>
            <input value={forms.offer} className='checkInSearch3' name='offer' onChange={handleChange} type="text" />
        </form>
        <div style={{'margin':'25px auto'}}>
            <button onClick={submitForm} className='checkInSubmit3'>Add</button>
        </div>
        </div>
        <div>
            <h3 className='headingalign'>Special Offer <UilInfoCircle style={{"marginLeft":'10px'}} /> <span style={{'fontSize':'10px'}}>Add special offer to display as advertisement in user interface.</span> </h3>
        </div>
        <div className='formbox8a'>
        <form action="" className='formcontainer2'>
            <label htmlFor="" className='addcustomerheader2'>Title</label>
            <input value={special.title} className='checkInSearch3' name='title' onChange={offerChange} type="text" />
            <label htmlFor="" className='addcustomerheader2'>Detail</label>
            <input value={special.detail} className='checkInSearch3a' name='detail' onChange={offerChange} type="textarea" />
        </form>
        <div style={{'margin':'25px auto'}}>
            <button onClick={specialOffer} className='checkInSubmit3'>Submit</button>
        </div>
        </div>

      </div>

    <div className='outertable'>
      <table className='table'>
        <thead>
            <tr>
                <th style={{'borderRadius':'20px 0 0 0 '}}>Points</th>
                <th>Offer</th>
                <th style={{'borderRadius':'0 20px 0 0 '}}>Edit</th>
            </tr>
        </thead>
        <tbody>
            {
                note?.map((not) => (
                    <tr key={not.id}>
                        <td>{not.points}</td>
                        <td>{not.offer}</td>
                        <td><Link to={'editoffer/' + not.id} >Edit</Link></td>
                    </tr>))
            }
        </tbody>
      </table>
    </div>

</div>
  )
}

export default AddOffer