import React, { useState, useEffect } from 'react'
import useAxios from '../utils/useAxios'
import { UilPlusCircle, UilTagAlt, UilApps } from '@iconscout/react-unicons'
import ID from '../Assets/id.png'
import name from '../Assets/name.png'
import visit from '../Assets/visit.png'
import purchase from '../Assets/purchase.png'
import Pos from '../components/Pos'


function CheckIn() {
  const [offer, setOffer] = useState('')
  const [added, setAdded] = useState(false)
  const [value, setValue] = useState('')
  const [newvalue, setNewvalue] = useState('')
  const [submit, setSubmit] = useState(true)
  const [search, setSearch] = useState('')
  const find = (data) => {
    return data.filter((item) => item.id == search)
  }

  let [note, setNote] = useState([])
  let getNote = async () => {
    let response = await api.get('/api/hotelhome/')
    if (response.status === 200) {
      setNote(response.data)
    }
  }

  let [privilege, setPrivilege] = useState([])
  let privi = async () => {
    let respo = await api.get('/api/set_privilage/')
    if (respo.status === 200) {
      setPrivilege(respo.data)
    }
  }

  let api = useAxios()
  useEffect(() => {
    getNote()
    privi()
  }, [search, note])
  useEffect(() =>{
    getPoints()
  },[])

  const submitForm = (id) => {
    setAdded(true)
    const formdata = new FormData()
    formdata.append('amount', value)
    try {
      api.put('/api/enteramount/' + id + '/', formdata).then((response) => {
        setNewvalue(value)
        setValue('')
      })
      alert('data is added')
    } catch (error) {
      setValue(error)
    }
  }

  let [calc,setCalc] = useState()
  let getPoints = async () => {
    let response = await api.get('/api/offer/' )

    if (response.status === 200) {
     setCalc(response.data)
    }
}

  const offerForm = (not) => {

    if (privilege.diamond_target_visit == 0 && privilege.diamond_target_purchase == 0) {

      if (privilege.gold_target_visit == 0 && privilege.gold_target_purchase == 0) {
        if (privilege.silver_target_visit == 0 && privilege.silver_target_purchase == 0) {
          return 'Fill Privilege Form'
        }
        else {
          return 'Silver'
        }
      }

      else {
        if (not.visit >= privilege.gold_target_visit && not.purchase >= privilege.gold_target_purchase) {
          return 'Gold'
        }
        else {
          return 'Silver'
        }
      }

    }


    else {
      if (not.visit >= privilege.diamond_target_visit && not.purchase >= privilege.diamond_target_purchase) {
        return (
          privilege.diamond_offer
        )
      }
      else if (not.visit >= privilege.gold_target_visit && not.purchase >= privilege.gold_target_purchase) {
        return (privilege.gold_offer)
      }
      else if(not.visit >= privilege.silver_target_visit && not.purchase >= privilege.silver_target_purchase){
        return (privilege.silver_offer)
      }
      else {
        return '0'
      }
    }

  }
  return (
    <>
      {submit ? <div className='checkin'>
        <form className='checkinform'>
          <div className='searchbox'>
            <input type="search" placeholder='Search...' className='checkInSearch' onChange={(e) => setSearch(e.target.value)} required />
            <button className='checkInSubmit' onClick={() => setSubmit(false)}>Search Customer</button>
          </div>
        </form>
      </div> :
        <div className='checkinwidth'>

          <div className='searchbox2'>
            <input type="search" placeholder='Search...' className='checkInSearch4' onChange={(e) => setSearch(e.target.value)} required />          
          {find(note).map(not => (
              <div className='formbox3'>
                <div key={not.id} className='singlecard'>
                  <div className='innersinglecard'>
                    
                    <div className='forgap'> 
                    <h4 style={{ 'display': 'flex', 'alignItems': 'center' }}><div className='iconback'><img src={ID} alt="" /></div> Customer ID : {not.id}</h4>
                    <h4 style={{ 'display': 'flex', 'alignItems': 'center' }}><div className='iconback2'><img src={name} alt="" /></div> Customer Name : {not.name}</h4>
                    {(not.visit >= privilege.silver_target_visit && not.purchase >= privilege.silver_target_purchase) &&
                      <h4 style={{ 'display': 'flex', 'alignItems': 'center' }}><div className='iconback2'><UilApps /></div> Category: {(() => {
                        if (privilege.diamond_target_visit == 0 && privilege.diamond_target_purchase == 0) {

                          if (privilege.gold_target_visit == 0 && privilege.gold_target_purchase == 0) {
                            if (privilege.silver_target_visit == 0 && privilege.silver_target_purchase == 0) {
                              return 'Fill Privilege Form'
                            }
                            else {
                              return 'Silver'
                            }
                          }

                          else {
                            if (not.visit >= privilege.gold_target_visit && not.purchase >= privilege.gold_target_purchase) {
                              return 'Gold'
                            }
                            else {
                              return 'Silver'
                            }
                          }

                        }


                        else {
                          if (not.visit >= privilege.diamond_target_visit && not.purchase >= privilege.diamond_target_purchase) {
                            return 'Diamond'
                          }
                          else if (not.visit >= privilege.gold_target_visit && not.purchase >= privilege.gold_target_purchase) {
                            return 'Gold'
                          }
                          else {
                            return 'Silver'
                          }
                        }
                      })()}</h4>}
                    {(not.visit >= privilege.silver_target_visit && not.purchase >= privilege.silver_target_purchase) &&
                      <h4 style={{ 'display': 'flex', 'alignItems': 'center' }}><div className='iconback2'><UilTagAlt /></div> Discount: {(() => {
                        if (privilege.diamond_target_visit == 0 && privilege.diamond_target_purchase == 0) {

                          if (privilege.gold_target_visit == 0 && privilege.gold_target_purchase == 0) {
                            if (privilege.silver_target_visit == 0 && privilege.silver_target_purchase == 0) {
                              return 'Fill Privilege Form'
                            }
                            else {
                              return 'Silver'
                            }
                          }

                          else {
                            if (not.visit >= privilege.gold_target_visit && not.purchase >= privilege.gold_target_purchase) {
                              return 'Gold'
                            }
                            else {
                              return 'Silver'
                            }
                          }

                        }


                        else {
                          if (not.visit >= privilege.diamond_target_visit && not.purchase >= privilege.diamond_target_purchase) {
                            return (
                              privilege.diamond_offer
                            )
                          }
                          else if (not.visit >= privilege.gold_target_visit && not.purchase >= privilege.gold_target_purchase) {
                            return (privilege.gold_offer)
                          }
                          else {
                            return (privilege.silver_offer)
                          }
                        }
                      })()}%</h4>}
                    </div>

                    <div className='forgap'>
                    <h4 style={{ 'display': 'flex', 'alignItems': 'center' }}> <div className='iconback3'><img src={visit} alt="" /></div> Total Visit: {not.visit}</h4>
                    <h4 style={{ 'display': 'flex', 'alignItems': 'center' }}> <div className='iconback4'><img src={purchase} alt="" /></div> Total Purchase: {not.purchase}</h4>
                    <h4 style={{ 'display': 'flex', 'alignItems': 'center' }}><div className='iconback2'><UilTagAlt /></div>Offer: {(()=>{
                        for(let i=0;i<(calc.length-1);i++){
                        if((not.purchase/100) >= calc[i].points && (not.purchase/100) <=calc[i+1].points ){
                            return calc[i].offer
                        }
                        else if((not.purchase/100) >= calc[(calc.length-1)].points){
                          return calc[(calc.length-1)].offer
                        }
                      }
                      
                      })()}</h4>
                      </div>
                  </div>
                </div>
              </div>
          ))}
        </div>

        {find(note).map(not=>
          <Pos not={not.id} offerForm={offerForm(not)} />
          )}

        </div>


      }

    </>
  )
}

export default CheckIn