import React, { useContext, useEffect, useState } from 'react'
import { useNavigate} from 'react-router-dom'
import AuthContext from '../context/AuthContext'
import Logo from '../components/Logo';
import { useParams } from 'react-router';
import jwt_decode from "jwt-decode";
import useAxios from '../utils/useAxios';

function CreatePassword() {
    const [pwd, setPwd] = useState({
        'oldpwd': '',
        'pwd1': '',
        'pwd2': ''
    })
    const handleChange = (e) => {
        setPwd({
            ...pwd,
            [e.target.name]: e.target.value
        })
    }
    const [validMatch, setValidMatch] = useState(false)
    useEffect(() => {
        setValidMatch(pwd.pwd1 === pwd.pwd2);
    }, [pwd.pwd1, pwd.pwd2])

    const baseURL = 'https://vips.vipsnepal.com'
    const navigate = useNavigate() 
    let [authTokens, setAuthTokens] = useState(()=> localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null)
    const [islogged,setIslogged] = useState(()=> localStorage.getItem('authTokens') ? true : false)
    let [user, setUser] = useState(()=> localStorage.getItem('authTokens') ? jwt_decode(localStorage.getItem('authTokens')) : null)

    // const {changePassword} = useContext(AuthContext)
    const params = useParams()

    const changePassword = async(e) =>{
        e.preventDefault()
        let response = await fetch(baseURL+'/api/change_password/'+params.id+'/', {
            method:'PUT',
            headers:{
                'Content-Type':'application/json',
                Authorization: `Bearer ${authTokens?.access}`
            },
            body:JSON.stringify({'old_password':e.target.oldpwd.value, 'password':e.target.pwd1.value, 'password2':e.target.pwd2.value})
        })
        // let data = await response.json()
        if(response.status === 200){
            console.log('sss');
        }else{
            alert('Something went wrong!')
        }
    }

    // const api = useAxios()
    // const changePassword = async(e) => {
    //     e.preventDefault()
    //     const formdata = new FormData()
    //     formdata.append('old_password', pwd.oldpwd)
    //     formdata.append('password1', pwd.pwd1)
    //     formdata.append('password2', pwd.pwd2)
    //     try {
    //         api.put(baseURL+'/api/change_password/'+params.id+'/', formdata).then((response) => {
    //             setPwd({
    //                 'oldpwd': '',
    //                 'pwd1': '',
    //                 'pwd2': ''
    //             })
    //             if (response.status === 200) {
    //                 navigate('/passform')
    //             }
    //             else {
    //                 alert('Error Occurred')
    //             } 
    //         })
    //     } catch (error) {
    //     }
    // }

    return (
        <>
        <Logo />
        <div className='App4'>
                <h1 className='addcustomerheader'>Create Your Own Password</h1>
            <div className='formbox4'>
                <div>
                <form onSubmit={changePassword} className='formcontainer2'>
                <label htmlFor="password" className='addcustomerheader2'>Old Password</label>
                <input
                    type="password"
                    name='oldpwd'
                    onChange={handleChange}
                    required
                    className='checkInSearch3'
                />
                <label htmlFor="password" className='addcustomerheader2'>New Password</label>
                <input
                    type="password"
                    name='pwd1'
                    onChange={handleChange}
                    required
                    className='checkInSearch3'
                />
                <label htmlFor="password" className='addcustomerheader2'>Re-Enter Password</label>
                <input
                    type="password"
                    name='pwd2'
                    onChange={handleChange}
                    required
                    className='checkInSearch3'
                />
                
                <button className={validMatch && "checkInSubmit4"} disabled={validMatch ? false : true}>Update</button>
               
                </form>
                </div>
            </div>
        </div>
        </>
    )
}

export default CreatePassword