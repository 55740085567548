import {
    UilEstate,
    UilClipboardAlt,
    UilUsersAlt,
    UilPackage,
    UilUserPlus
  } from "@iconscout/react-unicons";

  export const SidebarData = [
    {
      icon: UilEstate,
      heading: "Check In",
      to:'/'
    },
    {
      icon: UilClipboardAlt,
      heading: "All Customers",
      to:'/allcustomer'
    },
    {
      icon: UilUsersAlt,
      heading: "Privilege Customer",
      to:'/privilegecustomer'
    },
    {
      icon: UilUserPlus,
      heading: 'Add Customer',
      to:'/addcustomer'
    },
    {
      icon: UilPackage,
      heading: 'Add Offer',
      to:'/addoffer'
    }

  ];