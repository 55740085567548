import React from 'react'
import ChoosePlanNew from '../components/ChoosePlanNew'
import Header from '../components/Header'
import Logo from '../components/Logo'

function PassForm({setCount}) {
  return (
    // css on App.css
    <>
    <Logo />
    <div className='App3'>
   
    {/* <div className='App3'> */}
      
    
        <ChoosePlanNew />
        
        {/* </div> */}
        </div>
        </>
  )
}

export default PassForm