import React, { useContext } from 'react'
import AuthContext from '../context/AuthContext'

function Loading() {
  let {logoutUser} = useContext(AuthContext)
  return (
    <>
    <div className='loadingText'>
    <h3 style={{'color':'#ef4444'}}>
      If this loading icon is showing for several time,
      <br/>
       you can simply logout and try login again.
       <br/>
    </h3>
      <button className='checkInSubmit4' onClick={logoutUser}>Logout</button>
    </div>
<div className="lds-facebook"><div></div><div></div><div></div></div> 
</>
 )
}

export default Loading