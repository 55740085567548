import React, {useContext, useState} from 'react'
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import Logo from '../components/Logo';

function ForgetPassword() {
    
   let {reset_password} = useContext(AuthContext)

    const [requestSent, setRequestSent] = useState(false);
    // const [formData, setFormData] = useState({
    //     email: ''
    // });

    // const { email } = formData;

    // const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    // const onSubmit = e => {
    //     e.preventDefault();
    //     reset_password(email);
    //     setRequestSent(true);
    // };
  return (
    <>  
        <Logo />
      <div className='container1'>
            <h1>Request Password Reset:</h1>
            <form onSubmit={reset_password} style={{'marginTop':'1rem','display':'flex','flexDirection':'column','alignItems':'center'}}>
                <div className='form-group'>
                    <input
                        className='form-control'
                        type='email'
                        placeholder='Email'
                        name='email'
                        // value={email}
                        // onChange={e => onChange(e)}
                        required
                    />
                </div>
                <button style={{'marginTop':'8px'}} type='submit'>Reset Password</button>
            </form>
        </div>
        </>
  )
}

export default ForgetPassword