import React from 'react'
import { UilQrcodeScan } from '@iconscout/react-unicons'
 
function DigitalMenu() {
  return (
    <div className='menuItem link'>
        <UilQrcodeScan />
        <a className='link' href='https://vips.vipsnepal.com/qr/login/dashboard' target='blank' >Digital Menu</a>
    </div>
  )
}

export default DigitalMenu