import React, {useState, useEffect} from 'react'
import useAxios from '../utils/useAxios'
import './home.css'
import Loading from '../components/Loading'
import {Link} from 'react-router-dom'
import CheckIn from './CheckIn'


function AllCustomer() {
  const [search, setSearch] = useState('')
  const [loading,setLoading] = useState(false)
  const find = (data) => {
    return data.filter((item) => item.name.toUpperCase().includes(search) ||
                                 item.email.toUpperCase().includes(search) ||
                                 item.id == search ||
                                 item.phone_number.includes(search))
  }

  let [notes, setNotes] = useState([])

    let api = useAxios()
    useEffect(()=> {
        getNotes()
    }, [search])


    let getNotes = async() =>{
        let response = await api.get('/api/hotelhome/')

        if(response.status === 200){
           setNotes(response.data)
            setLoading(true)
        }
    }

  return (
    <div className='first'>
      {loading ? <>
      <div className='allcustomerheader'>
        <span>List Of The All Customers</span>
        <input type="search" className='allcustomerinput' placeholder='Search...' onChange={(e) => setSearch(e.target.value.toUpperCase())} />
        </div> 
        {/* index.css */}
        <div className='outertable'>
      <table className='table'>
    <thead>
      <tr>
        <th style={{'borderRadius':'20px 0 0 0 '}}>ID</th>
        <th>Name</th>
        <th>Email</th>
        <th>Contact</th>
        <th>Visit</th>
        <th>Purchase</th>
        <th style={{'borderRadius':'0 20px 0 0 '}}>CheckIn</th>
      </tr>
    </thead>
    <tbody>
      {find(notes).map(note =>(
        <tr style={{'fontSize':'18px'}} key={note.id}>
          <td style={{'color':'#2B3B47','fontWeight':'bolder'}}>{note.id}</td>
        <td><Link to={'customerdetail/'+note.id}><span style={{'color':'#2B3B47','fontWeight':'bolder'}}>{note.name}</span></Link></td>
        <td>{note.email}</td>
        <td>{note.phone_number}</td>
        <td><div style={{'background':'rgba(255, 242, 233, 0.8)','width':'60px','margin':'0 auto','padding':'2px 10px','borderRadius':'10px','color':'#9F6A0B'}}>{note.visit}</div></td>
        <td><div style={{'background':'rgba(120, 237, 160, 0.8)','width':'100px','margin':'0 auto','padding':'2px 10px','borderRadius':'10px','color':'#0E933B'}}>{note.purchase}</div></td>
        <td><Link to={'/'+note.id}>Checkin</Link></td>
      </tr>
      ))}
      
    </tbody>
      </table>
      </div>
      </> : <Loading />}

    </div>

  )
}

export default AllCustomer