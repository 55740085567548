import React from 'react'
import {Link} from 'react-router-dom'
import logo from '../Assets/logo.png'

function Logo() {
    return (
        <div className='vipslogo'>
            <Link to='/'><div><img src={logo} className='imgsize' alt="" /></div></Link>
        </div>
    )
}

export default Logo