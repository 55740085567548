import React, {useState,useEffect} from 'react'
import {Route, Routes, useNavigate} from 'react-router-dom'
import './home.css'
import Sidebar from '../components/Sidebar'
import AllCustomer from './AllCustomer'
import CheckIn from './CheckIn'
import AddCustomer from './AddCustomer'
import AddOldCustomer from './AddOldCustomer'
import PrivilegeCustomer from './PrivilegeCustomer'
import Header from '../components/Header'
import useAxios from '../utils/useAxios'
import PassForm from './PassForm'
import Loading from '../components/Loading'
import Logo from '../components/Logo'
import Editcustomeramount from './Editcustomeramount'
import Editsingleamount from './Editsingleamount'
import EachCheckIn from '../components/EachCheckIn'
import AddOffer from './AddOffer'
import EditOffer from './EditOffer'

const HomePage = () => {
  const[loading,setLoading] = useState(false)
  let api = useAxios()
  const [check,setCheck] = useState([])
  useEffect(()=>{
      getNotes()
  },[])
  let getNotes = async() =>{
    let response = await api.get('/api/set_privilage/')
    if(response.status === 200){
        setCheck(response.data)
        setLoading(true)
      }
}
const [expanded, setExpaned] = useState(false)
const handleClick = ()=>{
  setExpaned(!expanded)
}

    return (
      <>
      {/* <Logo /> */}
      {loading ?
       <>
        {(check.visit || check.purchase) ?
         <div className='homeapp'>
        <div className='homeappglass' onClick={()=>expanded && setExpaned(false)}>
          {/* <Header /> */}
          <div className='homecontainer'>
           <Sidebar expanded={expanded} handleClick={handleClick} /> 
           <Routes>
           <Route path='/' element={<CheckIn />}/>
           <Route path='/:id' element={<EachCheckIn />}/>
           <Route path='/allcustomer' element={<AllCustomer />}  />
           <Route path='/addcustomer' element={<AddCustomer/>}  />
           <Route path='/addoldcustomer' element={<AddOldCustomer />} />
           <Route path='/addoffer' element={<AddOffer />} />
           <Route path='/addoffer/editoffer/:id' element={<EditOffer />} />
           <Route path='/privilegecustomer' element={<PrivilegeCustomer />} />
           <Route path='/allcustomer/customerdetail/:id' element={<Editcustomeramount />} />
           <Route path='/allcustomer/customerdetail/:id/editsingleamount/:id' element={<Editsingleamount />} />
           </Routes>
          </div>
        </div>
      </div>  
      : <PassForm /> }
      </> 
       : <Loading />} 
      
       
      
         
      </>
    )
}

export default HomePage
