import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import useAxios from '../utils/useAxios'
import { Link } from 'react-router-dom'

function Editcustomeramount() {
    const params = useParams()
    let [note, setNote] = useState()

    useEffect(() => {
        getNotes()
        getNote()
    }, [])
    let api = useAxios()
    let getNotes = async () => {
        let response = await api.get('/api/detailcustomer/' + params.id)

        if (response.status === 200) {
         setNote(response.data)
        }
    }




    const [forms, setForms] = useState({
        'name': '',
        'email': '',
        'phone_number': '',
        'statuss': ''
    })
    let getNote = async () => {
        let response = await api.get('/api/editcustomer/' + params.id)
        if (response.status === 200) {
            setForms(response.data)
        }
    }
    const handleChange = (event) => {
        setForms({
            ...forms,
            [event.target.name]: event.target.value
        })
    }
    const submitForm = () => {
        const formdata = new FormData()
        formdata.append('name', forms.name)
        formdata.append('email', forms.email)
        formdata.append('phone_number', forms.phone_number)
        try {
            api.put('/api/editcustomer/' + params.id, formdata).then((response) => {
                setForms({
                    'name': '',
                    'email': '',
                    'phone_number': '',
                    'statuss': ''
                })
            })
        } catch {
        }
    }
    return (
        <>
            {/* app.css */}
            <div className='editcustomer' >
                {/* {find(forms)?.map(not => ( */}
                <div className='formbox4'>
                    <form action="" className='formcontainer2'>
                        <label htmlFor="" className='addcustomerheader2'>Full Name</label>
                        <input value={forms.name} className='checkInSearch3' name='name' onChange={handleChange} type="text" />
                        <label htmlFor="" className='addcustomerheader2'>Email</label>
                        <input value={forms.email} className='checkInSearch3' name='email' onChange={handleChange} type="email" />
                        <label htmlFor="" className='addcustomerheader2'>Phone No.</label>
                        <input value={forms.phone_number} className='checkInSearch3' name='phone_number' onChange={handleChange} type="number" />
                    </form>
                    <div style={{'margin':'25px auto'}}>
                        <button onClick={submitForm} className='checkInSubmit3'>Update</button>
                    </div>
                </div>
                {/* ))} */}
                <div className='outertable'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th style={{'borderRadius':'20px 0 0 0 '}}>Amount</th>
                            <th style={{'borderRadius':'0 20px 0 0 '}}>Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            note?.map((not) => (
                                <tr key={not.id}>
                                    <td>{not.amount}</td>
                                    <td><Link to={'editsingleamount/' + not.id} >Edit</Link></td>
                                </tr>))
                        }
                    </tbody>
                </table>
                </div>
            </div>
        </>
    )
}

export default Editcustomeramount