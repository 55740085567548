import React, { useContext, useState, useEffect } from 'react'
import AuthContext from '../context/AuthContext'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { UilSetting } from '@iconscout/react-unicons'
import { Link } from 'react-router-dom'
import useAxios from '../utils/useAxios';

const Header = () => {
    let { user, logoutUser } = useContext(AuthContext)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const api = useAxios()
    const [target,setTarget] = useState()
    useEffect(() => {
      getNotes()
    }, [])
    let getNotes = async () => {
      let response = await api.get('/api/set_privilage/')
      if (response.status === 200) {
        const data = response.data
        setTarget(data.user)
      }
    }

    return (
        // app.css
        <div>
            {user ? (
                  <div>
                  <div
                    id="demo-positioned-button"
                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    className="menuItem link"
                  >
                    {/* <SettingsIcon /> */}
                    <UilSetting />
                    <span>Setting</span>
                  </div>
                  <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    <MenuItem><Link style={{'textDecoration':'none','color':'black'}} to={'/createpassword/'+target+'/'}>Change Password</Link></MenuItem>
                    <MenuItem><Link style={{'textDecoration':'none','color':'black'}} to='/passform'>Change Privilege</Link></MenuItem>
                    <MenuItem onClick={logoutUser}>Logout</MenuItem>
                  </Menu>
                </div>
                //  <button onClick={logoutUser}>Logout</button>
            ) : (
                ""
            )}
        </div>
    )
}

export default Header
