import React, { useState, useEffect } from 'react'
import {useNavigate} from 'react-router-dom'
import { useParams } from 'react-router'
import useAxios from '../utils/useAxios'

function EditOffer() {


  const navigate = useNavigate()
  const params = useParams()
  let [note, setNote] = useState({
    'points': '',
    'offer':''
  })

  const handleChange = (event) => {
    setNote({
      ...note,
      [event.target.name]: event.target.value
    })
  }

  let api = useAxios()
  const getNotes =  () => {
    const formdata = new FormData()
    formdata.append('points', note.points)
    formdata.append('offer',note.offer)
    try {
       api.put('/api/editoffer/' + params.id , formdata).then((response) => {
        setNote({
          'points': '',
          'offer': ''
        })
        navigate(-1)
      })

    } catch {
    }
  }
  
  return (
    <div className='formbox7'>
        <div action="" className='formcontainer2'>
          <label htmlFor="" className='addcustomerheader2'>Points</label>
          <input type="number" value={note.points} className="checkInSearch3" name='points' onChange={handleChange} />
          <label htmlFor="" className='addcustomerheader2'>Offer</label>
          <input type="text" value={note.offer} className="checkInSearch3" name='offer' onChange={handleChange} />
        </div>
        <div style={{'margin':'25px auto'}}>
          <button onClick={getNotes} className='checkInSubmit3'>Update</button>
        </div>
      </div>
  )
}

export default EditOffer