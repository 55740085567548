import React, { useContext, useState } from 'react'
import AuthContext from '../context/AuthContext'
import useAxios from '../utils/useAxios'
function AddCustomer() {
    const {baseURL} = useContext(AuthContext)
    const [forms, setForms] = useState({
        'name': '',
        'email': '',
        'phone_number': '',
        'visit': '',
        'purchase': '',
        'statuss': ''
    })

    const handleChange = (event) => {
        setForms({
            ...forms,
            [event.target.name]: event.target.value
        })
    }
    const api = useAxios()
    const submitForm = () => {
        const formdata = new FormData()
        formdata.append('name', forms.name)
        formdata.append('email', forms.email)
        formdata.append('phone_number', forms.phone_number)
        formdata.append('visit',forms.visit)
        formdata.append('purchase',forms.purchase)
        try {
            api.post(`${baseURL}/api/oldcustomer/`, formdata).then((response) => {
                setForms({
                    'name': '',
                    'email': '',
                    'phone_number': '',
                    'visit':'',
                    'purchase':'',
                    'statuss': 'success'
                })
            }).catch(()=>{
                setForms({
                    'name': '',
                    'email': '',
                    'phone_number': '',
                    'visit': '',
                    'purchase': '',
                    'statuss':'error'
                })
            })
        } catch {
        }
    }





    return (
        <div style={{'margin':'auto'}}>
            {forms.statuss === 'success' && <p style={{'backgroundColor':'green','color':'white','marginBottom':'1rem'}}>Successfully Added</p>}
            {forms.statuss === 'error' && <p style={{'backgroundColor':'red','color':'white','marginBottom':'1rem'}}> &#128054; Data Not Added</p>}
              <h1 className='addcustomerheader'>Add Old Customer</h1>
            <div className="formbox6">
                <div>
                    <form className='formcontainer2'>
                        <label htmlFor="" className='addcustomerheader2'>Full Name</label> 
                            <input value={forms.name} name="name" required onChange={handleChange} type="text" className="checkInSearch3" />
                        <label htmlFor="" className='addcustomerheader2'>Email</label>
                            <input value={forms.email} name="email" onChange={handleChange} type="email" className="checkInSearch3" />
                        <label htmlFor="" className='addcustomerheader2'>Phone No.</label>
                            <input value={forms.phone_number} name="phone_number" onChange={handleChange} type="number" className="checkInSearch3" />
                        <label htmlFor="" className='addcustomerheader2'>Visit</label>
                            <input value={forms.visit} name="visit" onChange={handleChange} type="number" className="checkInSearch3" />
                        <label htmlFor="" className='addcustomerheader2'>Purchase</label>
                            <input value={forms.purchase} name="purchase" onChange={handleChange} type="number" className="checkInSearch3" />
                    </form>
                    <div style={{'margin':'25px auto'}}>
                        <button onClick={submitForm} className='checkInSubmit3'>ADD</button>
                    </div>

                </div>
            </div>

        </div>
    );

}

export default AddCustomer