import React, { useState, useEffect } from 'react'
import {Link} from 'react-router-dom'
import '../App.css'
import useAxios from '../utils/useAxios'


function ChoosePlanNew() {
  let api = useAxios()
  const [check, setCheck] = useState({
    'visit': false,
    'purchase': false
  })
  const [target, setTarget] = useState({
    'silver_target_visit': null,
    'silver_target_purchase': null,
    'silver_offer': null,
    'gold_target_visit': null,
    'gold_target_purchase': null,
    'gold_offer': null,
    'diamond_target_visit': null,
    'diamond_target_purchase': null,
    'diamond_offer': null
  })
  const handleChange = (e) => {
    setTarget({
      ...target,
      [e.target.name]: e.target.value
    })
  }
  const submitCheck1 = (event) => {
    event.target.checked ?
      setCheck(prev => ({
        ...prev,
        visit: true
      })) :
      setCheck(prev => ({
        ...prev,
        visit: false
      }))
  }
  const submitCheck2 = () => {
    setCheck(prev => ({
      ...prev,
      purchase: !prev.purchase
    }))
  }
  const handleSubmit = () => {
    let formdata = new FormData()
    formdata.append('visit', check.visit)
    formdata.append('purchase', check.purchase)
    formdata.append('silver_target_visit', (target.silver_target_visit === "") ? '0' : target.silver_target_visit)
    formdata.append('silver_target_purchase', (target.silver_target_purchase === "") ? '0' : target.silver_target_purchase)
    formdata.append('silver_offer', (target.silver_offer === "") ? '0' : target.silver_offer)
    formdata.append('gold_target_visit', (target.gold_target_visit === "") ? '0' : target.gold_target_visit)
    formdata.append('gold_target_purchase', (target.gold_target_purchase === "") ? '0' : target.gold_target_purchase)
    formdata.append('gold_offer', (target.gold_offer === "") ? '0' : target.gold_offer)
    formdata.append('diamond_target_visit', (target.diamond_target_visit === "") ? '0' : target.diamond_target_visit)
    formdata.append('diamond_target_purchase', (target.diamond_target_purchase === "") ? '0' : target.diamond_target_purchase)
    formdata.append('diamond_offer', (target.diamond_offer === "") ? '0' : target.diamond_offer)
    try {
      api.put('/api/set_privilage/', formdata).then((response) => {

        if (response.status === 200) {
          window.location.href = ('/')
        }
        else {
          alert('Error Occurred')
        }
      })
    } catch (error) {
      
    }
  }

  useEffect(() => {
    getNotes()
  }, [])
  let getNotes = async () => {
    let response = await api.get('/api/set_privilage/')
    if (response.status === 200) {
      setTarget(response.data)
    }
  }

  return (
    <>
      {/* <div className='sign-up-container'> */}
      {/* <div className='addcustomerheader'> */}
      <div className='changePass'>
       <span> If you're login for the first time, Please change your password <Link to={'/createpassword/'+target.user}> <span className='clickhere'> Click Here </span> </Link></span>
    </div>
      <div className='setup'>
        <span style={{ 'borderBottom': '3px solid #2B3B47' }}>Setup Privilege </span>
        {/* </div> */}
        <div className='checkbox'>

          <div className='gap'>
            <input style={{'width':'25px','height':'25px'}} type="checkbox" name='visit' onChange={submitCheck1} />
            <span>Visit</span>
          </div>

          <div className='gap'>
            <input style={{'width':'25px','height':'25px'}} type="checkbox" name='purchase' onChange={submitCheck2} />
            <span>Purchase</span>
          </div>

        </div>
        <table className='table2'>
          {(check.visit || check.purchase) &&
            <thead>
              <tr>
                <th></th>
                <th>Silver</th>
                <th>Gold</th>
                <th>Diamond</th>
              </tr>
            </thead>
          }
          <tbody>
            <tr>
              {check.visit && <>
                <td>  <label htmlFor="">Visit</label></td>
                <td> <input type="number" name='silver_target_visit' value={target.silver_target_visit} placeholder='Silver' onChange={handleChange} /> </td>
                <td> <input type="number" name='gold_target_visit' value={target.gold_target_visit} placeholder='Gold' onChange={handleChange} /> </td>
                <td> <input type="number" name='diamond_target_visit' value={target.diamond_target_visit} placeholder='Diamond' onChange={handleChange} /> </td>
              </>}
            </tr>
            <tr>
              {check.purchase && <>
                <td><label htmlFor="">Purchase</label></td>
                <td><input type="number" name='silver_target_purchase' value={target.silver_target_purchase} placeholder='Silver' onChange={handleChange} /></td>
                <td><input type="number" name='gold_target_purchase' value={target.gold_target_purchase} placeholder='Gold' onChange={handleChange} /></td>
                <td><input type="number" name='diamond_target_purchase' value={target.diamond_target_purchase} placeholder='Diamond' onChange={handleChange} /></td>
              </>}
            </tr>
            <tr>
              {(check.visit || check.purchase) && <>
                <td><label htmlFor="">Discount</label></td>
                <td><input name='silver_offer' value={target.silver_offer} placeholder='Silver' type='number' onChange={handleChange} /></td>
                <td><input name='gold_offer' value={target.gold_offer} placeholder='Gold' type='number' onChange={handleChange} /></td>
                <td><input name='diamond_offer' value={target.diamond_offer} placeholder='Diamond' type='number' onChange={handleChange} /></td>
              </>}
            </tr>
          </tbody>
        </table>
        <div style={{'margin':'25px auto'}}>
        <button className='checkInSubmit4' onClick={handleSubmit}>Submit</button>
        </div>
      </div>
      {/* </div> */}
    </>
  )
}

export default ChoosePlanNew