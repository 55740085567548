import React, {useContext, useState} from 'react'
import AuthContext from '../context/AuthContext'
import image from './../Assets/img.png'
import {Link} from 'react-router-dom'
import Logo from '../components/Logo'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';

const LoginPage = () => {
    let {loginUser,loadings} = useContext(AuthContext)
    return (
    <>
          { loadings && <Box sx={{ width: '100%' }}>
             <LinearProgress />
         </Box> }
            <Logo />
          <main className='App2'>
            <div className='logoimage'>
                <img src={image} alt=""  style={{'height':'80vh'}}/>
            </div>
            {/* index.css */}
          <section className='section2'>
          <h1>Login</h1>
          <Box component="form" onSubmit={loginUser} className='formcontainer'>
              <TextField
                  name="username"
                  required
                  label="Username"
                />
              <TextField
                  required
                  type="password"
                  label="Password"
                  name="password"
                />
           <a href="https://vips.vipsnepal.com/password_reset"><span style={{'fontSize':'20px','color':'#0404f9','fontWeight':'bold'}}>Forget Password</span></a>
           {/* app.css  */}
              <LoadingButton loading={loadings} variant='contained' type="submit" className='button'>Login</LoadingButton>
              <span>Don't have account?<a href="http://www.acetechnepal.com/#contact" target='_blank'><span style={{'color':'red'}}> Contact Us</span></a> </span>
              <p><a href="https://customer.vipsnepal.com" target='_blank'> Customer Login</a></p>
          </Box>
         
      </section>
      <div className='footers'>
        <span>Copyright 2022 AceTech Pvt Ltd. All Rights Reserved.</span>
      </div>
      </main>
      </>
    )
}

export default LoginPage
