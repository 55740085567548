import React, { useState, useEffect } from 'react'
import {useNavigate} from 'react-router-dom'
import { useParams } from 'react-router'
import useAxios from '../utils/useAxios'



function Editsingleamount() {
  const navigate = useNavigate()
  const params = useParams()
  let [note, setNote] = useState({
    'amount': ''
  })

  const handleChange = (event) => {
    setNote({
      ...note,
      [event.target.name]: event.target.value
    })
  }
  let api = useAxios()
  const getNotes =  () => {
    const formdata = new FormData()
    formdata.append('amount', note.amount)
    try {
       api.put('/api/editcustomeramount/' + params.id + '/', formdata).then((response) => {
        setNote({
          'amount': ''
        })
        navigate(-1)
      })

    } catch {
    }

    // if(response.status === 200){
    //     setNote(response.data)
    // }
  }
  return (
    <>
      <div className='formbox5'>
        <div action="" className='formcontainer2'>
          <label htmlFor="" className='addcustomerheader2'>Amount</label>
          <input type="number" value={note.amount} className="checkInSearch3" name='amount' onChange={handleChange} />
        </div>
        <div style={{'margin':'25px auto'}}>
          <button onClick={getNotes} className='checkInSubmit3'>Update</button>
        </div>
      </div>
    </>
  )
}

export default Editsingleamount