import React,{useContext, useState} from 'react'
import {SidebarData} from './Data'
import { UilBars } from "@iconscout/react-unicons";
import { NavLink } from 'react-router-dom'
import AuthContext from '../context/AuthContext';
import Header from './Header';
import DigitalMenu from './DigitalMenu'


function Sidebar({children,expanded,handleClick}) {
  const {user} = useContext(AuthContext)

    
  return (
    <>
      <div className="bars" style={expanded?{left: '60%'}:{left: '5%'}} onClick={handleClick}>
        <UilBars />
      </div>
    <div className={expanded ? ' sidebar expands' : ' sidebar not-expands'}
    >
      {/* logo */}
      
      <h3 className='headername'>{user.username}</h3>
      <div className="menu">
        
        {SidebarData.map((item, index) => (
          
            <NavLink to={item.to} 
            className= "menuItem link"
            activeclassname='active'
              key={index}
              onClick={handleClick}
            >
            
             
              <item.icon />
              <span style={{'fontWeight':'500'}}>{item.heading} </span>           
              </NavLink>
            
        ))}
        <DigitalMenu />
        <Header />


        
        
          
      </div>
    </div>
    <main>{children}</main>
    </>
  )
}

export default Sidebar